import * as React from 'react'

import styled from 'styled-components'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import ContainerLayout from '../../components/ui/containerLayout'

import fallingStar from '../../assets/images/elements/fallingStar.svg'
import helmet from '../../assets/images/elements/helmetWhite.png'
import controllerLeft from '../../assets/images/elements/controllerLeftWhite.png'
import controllerRight from '../../assets/images/elements/controllerRightWhite.png'
import greenCodeStrings from '../../assets/images/elements/greenCodeStrings.png'
import codeStringAlternative from '../../assets/images/elements/codeStringAlternative.png'

const Main = () => {
  const breakpoints = useBreakpoint()

  return (
    <ContainerLayouStyled>
      {breakpoints.medium ? null : (
        <StarStyled src={fallingStar} alt='Falling star' />
      )}

      <CodeStringGreenLeftStyled
        src={codeStringAlternative}
        alt='Matrix code'
      />

      <StarLeftStyled src={fallingStar} alt='Falling star' />
      <StarStyled src={fallingStar} alt='Falling star' />

      <HemletImageStyled src={helmet} alt='VR helmet' />
      <ControllerWrapStyled>
        <ControllerRightImageStyled src={controllerRight} alt='VR controller' />
        <ControllerLeftImageStyled src={controllerLeft} alt='VR controller' />
      </ControllerWrapStyled>

      <CodeStringBottomStyled src={greenCodeStrings} alt='Matrix code' />
    </ContainerLayouStyled>
  )
}
export default Main

const ContainerLayouStyled = styled(ContainerLayout)`
  flex-direction: column;

  margin-top: 3rem;
  padding-bottom: 0;

  @media ${(props) => props.theme.breakpoints.small} {
    margin-top: 5rem;
  }
`

const HemletImageStyled = styled.img`
  width: 340px;
  height: 210px;

  object-fit: contain;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.small} {
    width: 210px;
    height: 140px;
  }
`

const ControllerWrapStyled = styled.div`
  @media ${(props) => props.theme.breakpoints.small} {
    display: flex;
    align-items: center;

    gap: 60px;
  }
`

const ControllerLeftImageStyled = styled.img`
  margin-top: -30px;
  margin-left: 60px;
  margin-right: 50px;
  width: 115px;
  height: 130px;

  object-fit: contain;
  z-index: 1;
  @media ${(props) => props.theme.breakpoints.small} {
    margin: 0;
    width: 90px;
    height: 110px;
  }
`
const ControllerRightImageStyled = styled.img`
  margin-top: -30px;
  margin-right: 210px;
  width: 115px;
  height: 140px;

  object-fit: contain;
  z-index: 1;
  @media ${(props) => props.theme.breakpoints.small} {
    margin: 0;
    width: 70px;
    height: 100px;
  }
`

const StarLeftStyled = styled.img`
  width: 120px;
  height: 75px;

  position: absolute;
  top: 30%;
  left: 10%;
  z-index: 1;
  object-fit: contain;
  @media ${(props) => props.theme.breakpoints.small} {
    width: 90px;
    height: 60px;

    left: 60%;
    top: 3%;
  }
`
const StarStyled = styled.img`
  width: 150px;
  height: 90px;

  position: absolute;
  top: 85%;

  right: 12%;
  z-index: 1;
  object-fit: contain;
  @media ${(props) => props.theme.breakpoints.small} {
    width: 55px;
    height: 40px;

    right: 43%;
    top: 72%;
  }
`

const CodeStringGreenLeftStyled = styled.img`
  width: 160px;
  height: 410px;

  position: absolute;
  top: 20%;
  left: 10%;
  @media ${(props) => props.theme.breakpoints.small} {
    width: 120px;
    height: 360px;

    top: 0%;
    left: -5%;
  }
`
const CodeStringBottomStyled = styled.img`
  width: 160px;
  height: 380px;

  position: absolute;
  top: 10%;
  right: 10%;

  object-fit: contain;
  @media ${(props) => props.theme.breakpoints.small} {
    width: 120px;
    height: 320px;

    top: -5%;
    right: -3%;
  }
`
