import React, { useState, useContext } from 'react'

import styled from 'styled-components'

import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Button from '../../components/ui/buttons'
import ContainerLayout from '../../components/ui/containerLayout'
import GameModal from '../../components/games/gameModal'

import { i18n } from '../../i18n.js'
import { useI18n, LocaleContext } from '../../../locale.js'


import gamesArray from '../../components/games/data.js'

import activePlayer from '../../assets/icons/activePlayer.svg'
import unactivePlayer from '../../assets/icons/unactivePlayer.svg'
import { I18nextContext } from 'gatsby-plugin-react-i18next'

const filters = ['shooter', 'children', 'sport', 'simulator', 'quest', 'horror', 'magic', 'stategy', 'social-network']

const GamesList = ({ city, bookingRef }) => {
  const breakpoints = useBreakpoint()
  const { locale } = useContext(LocaleContext)
  const context = React.useContext(I18nextContext);
  const [selectedFilter, setselectedFilter] = useState('all');
  const [showSelect, setShowSelect] = useState(false)
  const [selectedGame, setSelectedGame] = useState(null)

  const whatToPlayText = useI18n('gamelist.whatToPlay')
  const allGamesText = useI18n('gamelist.allGames')
  const resetButtonText = useI18n('gamelist.reset')
  const moreDetailsButtonText = useI18n('gamelist.moreDetails')

  const isDefault = selectedFilter === 'all'

  const renderFilters = () => (
    <FilterListStyled>
      {breakpoints.small &&
        <FilterListItemStyled $isSelected={isDefault}>
          <HiddenCheckboxStyled
            type='checkbox'
            checked={isDefault}
            onChange={() => {
              setselectedFilter('all')
              setShowSelect(false)
            }}
          />
          {allGamesText}
        </FilterListItemStyled>
      }
      {filters.map((filter, i) => {
        const isSelected = selectedFilter === filter

        return (
          <FilterListItemStyled
            $isSelected={isSelected}
            key={i}
          >
            {!breakpoints.small &&
              <CustomCheckboxStyled
                aria-hidden='true'
                isSelected={isSelected}
              />
            }
            <HiddenCheckboxStyled
              type='checkbox'
              checked={isSelected}
              onChange={() => {
                setselectedFilter(isSelected ? 'all' : filter)
                setShowSelect(false)
              }}
            />
            {context.language && i18n[context.language].main.games[filter]}
          </FilterListItemStyled>
        )
      })}
    </FilterListStyled>
  )

  return (
    <ContainerLayouStyled>
      {breakpoints.small ? null : (
        <TitleStyled>{whatToPlayText}</TitleStyled>
      )}

      <BlockStyled>
        <FilterStyled>
          <FilterTitleStyled>{useI18n('gamelist.gameGenres')}</FilterTitleStyled>

          {breakpoints.small
            ? <SelectStyled onClick={() => setShowSelect(!showSelect)}>
                <SelectInputStyled>
                  <SelectInputValueStyled>{isDefault ? allGamesText : i18n[context.language].main.games[selectedFilter]}</SelectInputValueStyled>
                  <SelectInputArrowStyled $isOpened={showSelect} />
                </SelectInputStyled>
                <SelectMenuStyled $isOpened={showSelect}>
                  {renderFilters()}
                </SelectMenuStyled>
              </SelectStyled>
            : renderFilters()
          }

          {!isDefault && !breakpoints.small && (
            <ButtonStyled
              variant='outlined'
              title={resetButtonText}
              onClick={() => setselectedFilter('all')}
              defaultSize
            />
          )}
        </FilterStyled>

        <GameListStyled>
          {gamesArray
            .filter(game => selectedFilter === 'all' || game.genres.includes(selectedFilter))
            .map((game, i) => (
            <ItemStyled key={i} onClick={() => setSelectedGame(game)}>
              <ItemImageStyled src={require('../../assets/images/games/' + game.images[0]).default} alt='Game image' />
              <ItemBlockStyled>
                <div>
                  <ItemPlayerStyled src={activePlayer} alt='Active player' />
                  <ItemPlayerStyled
                    src={game.players > 1 ? activePlayer : unactivePlayer}
                    alt='Status player'
                  />
                  <ItemPlayerStyled
                    src={game.players > 2 ? activePlayer : unactivePlayer}
                    alt='Status player'
                  />
                  <ItemPlayerStyled
                    src={game.players > 3 ? activePlayer : unactivePlayer}
                    alt='Status player'
                  />
                </div>
                <ItemTitleStyled>{game.title}</ItemTitleStyled>
                <ButtonStyled
                  variant='solid'
                  title={moreDetailsButtonText}
                  defaultSize
                />
              </ItemBlockStyled>
            </ItemStyled>
          ))}
        </GameListStyled>
      </BlockStyled>
      {selectedGame !== null && (
        <GameModal
          showModal={true}
          onClose={() => setSelectedGame(null)}
          onBooking={() => {
            setSelectedGame(null)
            setTimeout(() => {
              bookingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }, 100);
          }}
          game={selectedGame}
          city={city}
        />
      )}
    </ContainerLayouStyled>
  )
}
export default GamesList

const ContainerLayouStyled = styled(ContainerLayout)`
  flex-direction: column;

  padding-bottom: 0;
`

const TitleStyled = styled.p`
  margin-bottom: 2rem;

  font-size: 2.6rem;
  line-height: 1.28;
  text-align: center;
`
const BlockStyled = styled.div`
  display: flex;

  width: 100%;
  flex: 1;
  @media ${(props) => props.theme.breakpoints.small} {
    flex-direction: column;
  }
`
const FilterStyled = styled.div`
  display: flex;
  flex-direction: column;

  margin-right: auto;
  padding-right: 2rem;
  flex: 1;
  @media ${(props) => props.theme.breakpoints.small} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin: 0 0 2rem 0;
    padding: 0;
    gap: 10px;
  }
`

const FilterTitleStyled = styled.p`
  font-family: ${(props) => props.theme.fonts.futuraDemi};
  font-size: 1.6rem;

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1rem;
  }
`
const FilterListStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: 30px 0;
  gap: 15px;
  @media ${(props) => props.theme.breakpoints.small} {
    align-items: center;

    margin: 0;
    gap: 10px;
  }
`

const CustomCheckboxStyled = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  padding: 0.2rem;

  border: 2px solid ${(props) => props.theme.colors.white};
  border-radius: 10px;
  &:before {
    content: ${(props) => (props.isSelected ? "''" : '')};
    width: 100%;
    height: 100%;

    background-color: ${(props) => props.theme.colors.green};
  }
`

const FilterListItemStyled = styled.label`
  display: flex;
  align-items: center;

  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;

  @media ${(props) => props.theme.breakpoints.small} {
    justify-content: center;
    width: 100%;
    padding: 0.2rem 0;

    background-color: ${(props) =>
      props.$isSelected ? props.theme.colors.green : ''};

    color: ${(props) =>
      props.$isSelected ? props.theme.colors.white : props.theme.colors.black};
    text-align: center;
    font-size: 1rem;
    line-height: 1;
  }

  &:hover ${CustomCheckboxStyled}:before {
    content: '';
  }
`

const HiddenCheckboxStyled = styled.input`
  height: 1px;
  width: 1px;

  position: absolute;

  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
`

const ButtonStyled = styled(Button)`
  width: 10rem;
  height: 2.5rem;
  @media ${(props) => props.theme.breakpoints.medium} {
    width: 100%;
  }
  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 2.25rem;
  }
`

const GameListStyled = styled.div`
  display: grid;
  flex: 3;

  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const ItemBlockStyled = styled.div`
  margin-bottom: 115px;
  transform: translateY(100%);
  padding: 0 28px 20px;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  overflow: hidden;
  transition: 0.3s ease-in-out;
  @media ${(props) => props.theme.breakpoints.medium} {
    padding: 0 20px 20px;
    margin-bottom: 90px;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 0 15px 20px;
  }
`

const ItemStyled = styled.div`
  position: relative;

  border-radius: 9px;

  overflow: hidden;
  cursor: pointer;
  &:after {
    content: '';
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    opacity: 0;
    z-index: 1;

    background-color: ${(props) => props.theme.colors.black};
    transition: 0.3s ease-in-out;
  }

  &:hover:after {
    opacity: 0.5;
  }
  &:hover ${ItemBlockStyled} {
    margin-bottom: 0;
    transform: translateY(0%);
  }
`

const ItemImageStyled = styled.img`
  display: block;

  width: 100%;
  min-height: 330px;

  border-radius: 9px;
  object-fit: cover;
  @media ${(props) => props.theme.breakpoints.medium} {
    min-height: 250px;
  }
`

const ItemTitleStyled = styled.span`
  display: block;

  margin: 0.25rem 0 1.5rem;

  font-size: 1.4rem;
  font-family: ${(props) => props.theme.fonts.futuraDemi};
  @media ${(props) => props.theme.breakpoints.medium} {
    margin: 0 0 1rem;

    font-size: 1.2rem;
    white-space: nowrap;
  }
`

const ItemPlayerStyled = styled.img`
  height: 52px;
  width: 18px;
  margin-right: 5px;

  object-fit: contain;
  @media ${(props) => props.theme.breakpoints.small} {
    height: 45px;
    width: 15px;
    margin-right: 2px;
  }
`
const SelectStyled = styled.button`
  width: 100%;
  padding: 8px 25px;
  max-width: 45%;
  flex: 1;

  position: relative;

  background-color: unset;
  border: 1px solid ${(props) => props.theme.colors.white};
`
const SelectInputStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1rem;
  color: ${(props) => props.theme.colors.white};
`
const SelectInputValueStyled = styled.div`
  height: 22px;

  overflow: hidden;
  word-break: break-word;
  line-height: normal;
`

const SelectInputArrowStyled = styled.span`
  width: 0;
  height: 0;

  position: absolute;
  right: 10px;

  border-top: ${({ $isOpened }) => ($isOpened ? '' : '5px solid #2fa300')};
  border-bottom: ${({ $isOpened }) => ($isOpened ? '5px solid #2FA300' : '')};
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
`
const SelectMenuStyled = styled.div`
  width: calc(100% + 2px);
  padding: 20px;
  margin-top: ${({ $isOpened }) => ($isOpened ? '10px' : '')};

  position: absolute;
  top: 100%;
  left: -1px;

  border: 1px solid #fff;
  background-color: #fff;

  visibility: ${({ $isOpened }) => ($isOpened ? 'visible' : 'hidden')};
  opacity: ${({ $isOpened }) => ($isOpened ? 1 : 0)};
  transition: all 0.2s;
  z-index: 3;
`
