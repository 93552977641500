import React, { useContext } from 'react'

import styled, { createGlobalStyle } from 'styled-components'
import { navigate } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Slider from 'react-slick'
import Modal from 'react-modal'

import { LocaleContext, useI18n } from '../../../locale.js'
import '../ui/sliderStyles.css'

import Button from '../../components/ui/buttons'

import activePlayer from '../../assets/icons/activePlayer.svg'
import unactivePlayer from '../../assets/icons/unactivePlayer.svg'

import { i18n } from '../../i18n.js'
import { I18nextContext } from 'gatsby-plugin-react-i18next'

const GameModal = ({ showModal, onClose, game, onBooking }) => {
  const { locale } = useContext(LocaleContext)
  const context = React.useContext(I18nextContext);
  const breakpoints = useBreakpoint()

  const settings = {
    arrows: false,
    customPaging: function (i) {
      return <GameThumbnailStyled src={require('../../assets/images/games/' +  game.images[i]).default} alt={game.title} />
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    className: 'center'
  }

  const playersShortText = useI18n('main.games.playersShort')
  const playersLongText = useI18n('main.games.playersLong')
  const bookButtonText = useI18n('main.games.book')

  return (
    <ModalStyled
      isOpen={showModal}
      onRequestClose={onClose}
      style={{
        overlay: {
          zIndex: '100',
          backgroundColor: 'rgba(0, 0, 0, 0.8)'
        }
      }}
      ariaHideApp={false}
    >
      <GlobalStyle />
      <ModalBackgroundStyled onClick={onClose} />
      <ModalBodyStyled>
        <CloseButtonStyled onClick={onClose} />

        <ModalTitleStyled>{game.title}</ModalTitleStyled>

        <SliderWrapStyled>
          <Slider {...settings}>
            {game.images.map((image, i) =>
              <SliderItemStyled key={i}>
                <SliderItemImageStyled src={require('../../assets/images/games/' + image).default} />
              </SliderItemStyled>
            )}
          </Slider>
        </SliderWrapStyled>

        <DescriptionStyled>
          <BlockStyled>
            <RowStyled>
              <RowLabelStyled>
                {breakpoints.small ? playersShortText : playersLongText}
              </RowLabelStyled>
              <RowBlockStyled>
                <div>
                  <ItemPlayerStyled src={activePlayer} alt='Active player' />
                  <ItemPlayerStyled
                    src={game.players > 1 ? activePlayer : unactivePlayer}
                    alt='Status player'
                  />
                  <ItemPlayerStyled
                    src={game.players > 2 ? activePlayer : unactivePlayer}
                    alt='Status player'
                  />
                  <ItemPlayerStyled
                    src={game.players > 3 ? activePlayer : unactivePlayer}
                    alt='Status player'
                  />
                </div>
              </RowBlockStyled>
            </RowStyled>

            <RowStyled>
              <RowLabelStyled>{useI18n('main.games.genre')}</RowLabelStyled>
              <RowBlockStyled>
                {game.genres.map((genre, i) =>
                  <RowBlockTextStyled key={i}>
                    {context.language && i18n[context.language].main.games[genre]}
                  </RowBlockTextStyled>
                )}
              </RowBlockStyled>
            </RowStyled>
          </BlockStyled>

          <BlockStyled>
            <RowStyled>
              <RowLabelStyled>{useI18n('main.main.games.description')}</RowLabelStyled>
              <RowBlockStyled>
                <RowBlockTextStyled>
                  {game.description[context.language]}
                </RowBlockTextStyled>
              </RowBlockStyled>
            </RowStyled>
            {breakpoints.small ? null : (
              <ButtonStyled
                variant='solid'
                title={bookButtonText}
                onClick={onBooking}
              />
            )}
          </BlockStyled>
        </DescriptionStyled>
        {breakpoints.small ? (
          <ButtonStyled
            variant='solid'
            title={bookButtonText}
            onClick={onBooking}
          />
        ) : null}
      </ModalBodyStyled>
    </ModalStyled>
  )
}

export default GameModal

const ModalStyled = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  max-height: 100%;
  padding: 20px;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  overflow: auto;
  @media ${(props) => props.theme.breakpoints.small} {
    padding: 0;
  }
`
const ModalBackgroundStyled = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
`

const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 700px;
  width: 100%;
  margin: auto;
  padding: 40px;
  gap: 25px;

  position: relative;
  z-index: 1;

  background-color: #1d1d1d;

  color: ${(props) => props.theme.colors.white};
  @media ${(props) => props.theme.breakpoints.small} {
    min-height: 100vh;
    padding: 40px 20px;
    max-width: 100%;
    gap: 15px;

    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 20px;
  }
`

const CloseButtonStyled = styled.button`
  width: 25px;
  height: 25px;
  padding: 0px;

  position: absolute;
  right: 32px;
  top: 32px;

  background: none;
  border: none;
  opacity: 0.3;
  transition: 0.3s all;
  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    content: ' ';
    height: 26px;
    width: 2px;

    position: absolute;
    top: 0;
    left: 11px;

    background-color: ${(props) => props.theme.colors.green};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    right: 20px;
    top: 20px;
  }
`

const ModalTitleStyled = styled.p`
  font-size: 1.8rem;
  color: inherit;
  font-family: ${(props) => props.theme.fonts.futuraDemi};
  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1.6rem;
  }
`

const SliderWrapStyled = styled.div`
  display: flex;

  width: 100%;
  .slick-slider {
    padding-bottom: 100px;
    @media ${(props) => props.theme.breakpoints.small} {
      padding-bottom: 60px;
    }
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;

    width: 100%;
    max-width: 100%;
    gap: 15px;
    bottom: 0px;

    overflow: auto;
    li {
      display: flex;

      opacity: 0.5;
      cursor: pointer;
      &.slick-active {
        opacity: 1;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
    @media ${(props) => props.theme.breakpoints.tablet} {
      justify-content: flex-start;

      gap: 10px;
    }
  }
`

const SliderItemStyled = styled.div`
  display: flex !important;
  justify-content: center;
`
const SliderItemImageStyled = styled.img`
  display: block;

  width: 100%;
  height: 300px;

  border-radius: 9px;
  object-fit: cover;
  @media ${(props) => props.theme.breakpoints.small} {
    max-height: 180px;
  }
`
const DescriptionStyled = styled.div`
  display: flex;
  @media ${(props) => props.theme.breakpoints.small} {
    flex-direction: column;
    gap: 20px;
  }
`
const BlockStyled = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  &:last-child {
    flex: 1.5;
  }
  @media ${(props) => props.theme.breakpoints.small} {
    flex-direction: row;
    gap: 10px;
  }
`

const RowStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 10px;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  @media ${(props) => props.theme.breakpoints.small} {
    flex: 1;
    &:not(:last-child) {
      flex: 1.5;
    }
  }
`

const ItemPlayerStyled = styled.img`
  height: 52px;
  width: 18px;
  margin-right: 5px;

  object-fit: contain;
  @media ${(props) => props.theme.breakpoints.small} {
    height: 45px;
    width: 15px;
    margin-right: 2px;
  }
`

const RowLabelStyled = styled.p`
  font-size: 1.2rem;
  color: inherit;
  font-family: ${(props) => props.theme.fonts.futuraDemi};
`

const RowBlockStyled = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
  @media ${(props) => props.theme.breakpoints.small} {
    flex-direction: column;
    align-items: flex-start;

    gap: 0px;
  }
`
const RowBlockTextStyled = styled.p`
  font-size: 1rem;
  color: inherit;
`

const ButtonStyled = styled(Button)`
  @media ${(props) => props.theme.breakpoints.small} {
    width: 100%;
    margin-top: auto;
  }
`

const GlobalStyle = createGlobalStyle`
  html {
    overflow: hidden;
  }
`
const GameThumbnailStyled = styled.img`
  width: 120px;
  height: 80px;

  object-fit: cover;
  @media ${(props) => props.theme.breakpoints.small} {
    width: 65px;
    height: 45px;
  }
`
