import React, { useRef } from 'react'

import styled from 'styled-components'

import NavBar from '../../components/navbar'
import Reviews from '../../components/reviews'
import Contacts from '../../components/contacts'
import Footer from '../../components/footer'

import Main from './Main'
import GamesList from './GamesList'
import Booking from '../city/Booking'
import Seo from '../../components/seo'
import { graphql } from 'gatsby'

const Games = ({ pageContext }) => {
  const bookingRef = useRef();

  return (
    <ContainerStyled id='games-page'>
      <NavBar city={pageContext} />
      <Main />
      <GamesList city={pageContext} bookingRef={bookingRef} />
      <Reviews />
      <Booking city={pageContext} ref={bookingRef} />
      <Contacts city={pageContext} />
      <Footer city={pageContext} />
    </ContainerStyled>
  )
}

export const Head = ({ pageContext }) => (
  <>
    <SeoData context={pageContext} />
    <script
      dangerouslySetInnerHTML={{
        __html: `
            !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '1258133411516313'); 
fbq('track', 'PageView');
            `
      }}
    />
    <noscript>
      <img height="1" width="1"
        src="https://www.facebook.com/tr?id=1258133411516313&ev=PageView
&noscript=1"/>
    </noscript>
    {!!pageContext.fbPersonalId &&
      <>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '${pageContext.fbPersonalId}'); 
fbq('track', 'PageView');
            `
          }}
        />
        <noscript>
          <img height="1" width="1"
            src={`https://www.facebook.com/tr?id=${pageContext.fbPersonalId}&ev=PageView
&noscript=1`} />
        </noscript>
      </>
    }
  </>
)
const SeoData = ({ context }) => {
  const [lang, setLang] = React.useState('ua')
  return (
          <Seo
          context={context}
          title={`У що можна погратись?`}
          description={`Клуб MATRIX у ${context.declension[lang]} пропонує своїм клієнтам тільки ті ігри, що відрізняються чудовою графікою та чітким зображенням. Це є запорукою максимального занурення та ваших позитивних емоцій.`}
         />
  )
}

export default Games

const ContainerStyled = styled.div`
  min-height: 100vh;
  background: #030303;
  overflow: hidden;
`
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;